@import "./../../assets/css/variables.scss";

.mainMenuWrap {
  position: relative;
  z-index: 102;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_2D {
    .mainMenu {
      padding: 0 0 0 5px;
      @media screen and (max-width: $breakpoint-mob-max) {
        padding: 0 0 3px 0;
      }
    }
    .mainMenuLink {
      gap: 16px;
      > img {
        flex: 20px 0 0;
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
}
.mainMenuLogoWrap {
  margin-bottom: 50px;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: none;
  }
}
.mainMenu {
  padding: 0 10px;
  @media screen and (max-width: $breakpoint-mob-max) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 3px 0;
    overflow: visible;
    overflow-x: auto;
    border-bottom: 3px solid #D9D9D9;
    &::-webkit-scrollbar-track {
      background: #D9D9D9; /* цвет фона у дорожки */
      box-shadow: none; /* тень у дорожки */
      border-radius: 0;
    }
    &::-webkit-scrollbar-button {
      background: #D9D9D9;
      width: 4px;
    }
    /* полоса прокрутки (скроллбар) */
    &::-webkit-scrollbar {
      width: 5px;
      /* ширина для вертикального скролла */
      height: 3px;
      /* высота для горизонтального скролла */
      background-color: transparent;
    }
    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      transition: all 0.2s;
      cursor: pointer;
      background: #000000;
      border: none;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }
  }
}
.mainMenuLink {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 8px;
  gap: 10px;
  border-radius: 2px;
  margin-bottom: 23px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  @media screen and (min-width: $breakpoint-mob-min) {
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    margin: 0 8px;
    white-space: nowrap;
    &::before {
      content: '';
      position: absolute;
      height: 3px;
      width: calc(100% + 16px);
      bottom: -3px;
      left: -8px;
      background: #D9D9D9;
    }
  }
  &.active {
    background: rgba(255, 255, 255, 0.2);
  }
  img {
    flex: 40px 0 0;
  }
  .mainMenuLinktext {
    flex: auto 1 1;
    text-transform: capitalize;
    margin: 11px 0;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin: 0;
    }
  }
}

.menuItemSubContent {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 100%;
}