.mainLoading {
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #ffffff, $alpha: .8);

  display: flex;
  align-items: center;
  justify-content: center;
}